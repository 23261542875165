<template>
  <div class="profile-container mt-4" v-if="actor">
    <profile-form
      @actorChanged="onActorChange"
      :actor="actor"
      :backTo="{ name: 'ProfileIndex', params: { id: actor._id } }"
    >
      <template v-slot:submit>
        <Button
          @click="updateActor"
          :disabled="!updatedActor"
          class="action-btn"
          >Zapisz zmiany</Button
        >
      </template>
    </profile-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ProfileForm from "../../components/ProfileForm.vue";
import { toastMixin } from "../../mixins/toastMixin.js";
import { isUrl } from "../../helpers/isUrl";
export default {
  name: "EditProfile",
  components: { ProfileForm },
  mixins: [toastMixin],
  data() {
    return {
      updatedActor: null,
      urlFields: [
        { type: "polish_movie", name: "Filmpolski.pl" },
        { type: "actor_page", name: "Strona aktora" },
      ],
    };
  },
  methods: {
    ...mapActions("actors", { storeUpdateActor: "updateActor" }),
    validateActor() {
      this.validateUrls();
      const requiredFields = [
        { type: "first_name", name: "Imię" },
        { type: "last_name", name: "Nazwisko" },
        { type: "phone_number", name: "Numer telefonu" },
      ];
      requiredFields.forEach((field) => {
        if (!this.updatedActor[field.type]) {
          throw { type: "required", field: field.name };
        }
      });
    },
    validateUrls() {
      this.urlFields.forEach((field) => {
        if (!isUrl(this.updatedActor[field.type])) {
          throw { type: "invalidUrl", field: field.name };
        }
      });
    },
    async updateActor() {
      try {
        this.validateActor();
        await this.storeUpdateActor(this.updatedActor);
        this.displayToast(
          "success",
          "Aktor został poprawnie zaktualizowany, za chwilę nastąpi przekierowanie do profilu aktora!"
        );
        setTimeout(() => {
          this.$router.push({
            name: "ProfileIndex",
            params: { id: this.actor._id },
          });
        }, 2500);
      } catch (err) {
        if (err.type === "required") {
          this.displayToast("error", `Pole ${err.field} jest wymagane!`);
        } else if (err.type === "invalidUrl") {
          this.displayToast(
            "error",
            `Pole ${err.field} nie jest prawidłowym adresem URL.`
          );
        } else {
          this.displayToast("error", "Wystąpił błąd w trakcie edycji aktora");
        }
      }
    },
    onActorChange(updatedActor) {
      this.updatedActor = { ...updatedActor };
    },
  },
  computed: {
    ...mapGetters("actors", ["actor"]),
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables";
@import "../../styles/main";
</style>
